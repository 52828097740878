import React, { useState, useEffect } from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Contact from './routes/Contact'

import Home from './routes/Home'
import About from './routes/About'
import Services from './routes/Services'
import CookiePolicy from './routes/CookiePolicy'
import PrivacyPolicy from './routes/PrivacyPolicy'
import TermsOfService from './routes/TermsOfService'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-238956408-1',{debug: false, gaOptions: {siteSpeedSampleRate: 100} });
//ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
        <Routes>
          <Route  path='/' element={<Home />} />
          <Route  path='/home' element={<Home />} />
          <Route  path='/services' element={<Services />} />
          <Route  path='/contact' element={<Contact />} />
          <Route  path='/about' element={<About />} />

          <Route  path='/termsofservice' element={<TermsOfService />} />
          <Route  path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route  path='/cookiepolicy' element={<CookiePolicy />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
