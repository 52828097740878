import React from 'react'
import Footer from '../components/Footer'
import PageLayout from '../components/PageLayout'
import Navbar from '../components/Navbar'
import PrivacyPolicyPage from '../components/legal/PrivacyPolicy'
import ScrollToTop from "../components/utils/ScrollToTop";
import ReactGA from 'react-ga';
import { useState, useEffect } from "react";

const PrivacyPolicy = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);
    
    return (
        <div>
            <ScrollToTop />
            <Navbar />
            <PageLayout heading='Privacy Policy' text='' />
            <PrivacyPolicyPage/>
            <Footer/>
        </div>
        
    )
}

export default PrivacyPolicy
