import React from 'react'
import Footer from '../components/Footer'
import PageLayout from '../components/PageLayout'
import Navbar from '../components/Navbar'
import AboutPage from '../components/About'
import ScrollToTop from "../components/utils/ScrollToTop";
import ReactGA from 'react-ga';
import { useState, useEffect } from "react";


const About = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);
    
    return (
        <div>
            <ScrollToTop />
            <Navbar />
            <PageLayout heading='About' text='' />
            <AboutPage />
            <Footer />
        </div>
        
    )
}

export default About
