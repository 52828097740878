import { useEffect } from "react";
import { useLocation } from "react-router-dom";
{/*
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

}
function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);*/}


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'auto', // <-- The scrolling happens in a single jump
    });
  }, [pathname]);

  return null;
}
export default ScrollToTop