import React,{useRef, useEffect, useState} from "react";
import './AboutStyles.css'
import truck_sainsbury from '../assets/truck_sainsbury.jpg'
import truck_white_own_trailer from '../assets/truck_white_own_trailer.jpg'
import ReactGA from 'react-ga';

const About = () => {

    const [imgIsLoading, setImgIsLoading] = useState(true)
    const [pageSelected,setSelected] = useState(true)

    function openCity(evt, cityName) {
        var i, tabcontent, tablinks;

        setSelected(false)

        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace("active", "");
        }

        document.getElementById(cityName).style.display = "block";
        evt.currentTarget.className +=" active";
    }

    useEffect(() => {
        if (pageSelected)
        document.getElementById("defaultOpen").click();
      }, []);
 
    return (
        
        <div className="about">
            <div className="left">
                <div className="description">
                    <h1>About N.Y.A & SONS Trans LTD</h1>

                    
                    <div style={{textAlign:"left", marginTop:'20px'}}>
                        <div className="tab">
                            <button className="tablinks" onClick={e=>openCity(e,'Vison')} id="defaultOpen">About Us</button>
                            <button className="tablinks" onClick={e=>openCity(e,'Offer')}>Our Services</button>
                            <button className="tablinks" onClick={e=>openCity(e,'Ours')}>Our Unique Approach</button>
                        </div>
                        
                        <div id="Vison" className="tabcontent">
                            <h2>About Us</h2>
                            <p>
                                For almost 10 years, NYA & Sons Trans LTD has had an excellent 
                                reputation for providing the highest standard of professional service 
                                to its customers in Hampshire. 
                                <br/><br/>
                                If you are looking for a transport service provider, 
                                that has been providing quality services for many years 
                                and has a great reputation for offering superior service, 
                                as well as top quality and cost-effective freight services, 
                                then we cannot wait to do business with you.
                                <br/><br/>
                                Tell us about the goods and services that you require, their
                                estimated weights and the route you require, and we will produce
                                an estimate with costings based on likely duty/tax and delivery. 
                                You will be asked to provide any export documentation required, 
                                as well as the type and value of the goods that you are transporting.
                                <br/><br/>
                                Guarantee outstanding customer service and working with dedicated 
                                and professional staff. This is a day to day experience at every 
                                stage of the supply chain with every step in the process analysed 
                                to give the best possible service to all our clients.
                                <br/><br/>
                                Our dedication includes promptly and professionally responding to 
                                your requests. We conduct business with precision, 
                                prioritizing top-notch customer satisfaction. If you seek exceptional, 
                                reliable, and prompt service coupled with a first-class reputation, 
                                our company is the perfect choice for you.
                                <br/><br/>
                            </p>
                        </div>
                        

                        <div id="Offer" className="tabcontent">
                            <h2>Our Services</h2>
                            <p> 
                            We can provide a wide range of services:<br/>
                                • More than 30 Euro-6 tractors that are completly tracked at all time with live visibility.<br/>
                                • Traction services<br/>
                                • Curtainsider<br/>
                                • Dry boxed trailers<br/>
                                • Skeleton trailers for containers<br/>
                                • Flat bed trailers<br/>
                                • Low loader trailer<br/>
                                • Refrigerated trailers<br/>
                                • Water tank trailers<br/>
                                • HIAB trucks and trailers<br/>
                                In addition to providing excellent service and covering the Hampshire area, we can also provide services across all of Britain and Europe.
                                If you are in search of a transportation service provider with a proven track record of delivering quality services over many years and enjoys 
                                an excellent reputation for providing superior service, along with top-quality and cost-effective freight services, we eagerly anticipate the 
                                opportunity to engage in business with you
                            </p>
                            
                        </div>
                        <div id="Ours" className="tabcontent">
                            <h2 >Our Unique Approach</h2>
                                <p> 
                                    1. We take pride in our client retention, 
                                    and we assure you exceptional service, 
                                    a high level of continuity, and commitment 
                                    to promptly and professionally respond to your requests. 
                                    <br/><br/>2. We are particular about the way we do business,
                                     and we give priority to quality customer satisfaction.
                                    <br/><br/>3. We deliver our services to customers throughout the UK, 
                                    as well as the European mainland.
                                </p>

                        </div>
                        
                    </div>
                    <script>
                    // Get the element with id="defaultOpen" and click on it
                    document.getElementById("defaultOpen").click();
                    </script>

                    <br/>
                    <h3 style={{ textAlign:'left',  color:'white'}}>  Please let us know what further improvement we could make.</h3>

                    <h2 style={{ textAlign:'center',  color:'white'}}> </h2>
                </div>
            </div>
           
            <div className="right">
                <div className="img_container">             
                    {/*<div className="image_stack top">
                        <img  src={truck_sainsbury} className="img" alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                    </div> */}

                    <div className="image_stack bottom">
                        <img  src={truck_white_own_trailer} className="img" alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                    </div>
                </div>
            </div>  
        </div>
    )
}

export default About

{/**
    const [checkout, setCheckout] = React.useState(false);
    const [paid, setPaid] = React.useState(false);

    function purchaseTimer(e) {
        setCheckout(true);
        setTimeout(() => {purchaseTimedOut(false);}, 300000);
        return false;
    }

    function purchaseTimedOut(e) {
        console.log("Finished")
        setCheckout(false);
    } */}