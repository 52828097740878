import React, { Component } from 'react'
import style from './PageLayoutStyles.module.css'

class PageLayout extends Component {
    
    render() {
        return (
                <div>
                    <div className={style.heading}>
                        <h1>{this.props.heading}</h1>
                        <p>{this.props.text}</p>
                    </div>
                    
                    <div className={style.hero_bkg}>
                            <div className={style.hero_bkg_mask}>
                                
                            </div>
                    </div>
                </div>
        )
    }
}

export default PageLayout
/*
<div className='heading'>
                    <h1>{this.props.heading}</h1>
                    <p>{this.props.text}</p>
                </div>
*/