import React, { Component } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import HomePage from '../components/Home'
import PageLayout from '../components/PageLayout'
//import CookieConsent, { Cookies } from "react-cookie-consent"
import ScrollToTop from "../components/utils/ScrollToTop";
import ReactGA from 'react-ga';
import { useState, useEffect } from "react";

const Home = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);
    
    return (
        <div>
            <ScrollToTop />
            <Navbar page='home'/>
            <PageLayout heading='N.Y.A & SONS Trans LTD' text='' />
            <HomePage /> 
            <Footer/>
        </div>
    )
}

export default Home
