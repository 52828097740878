import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import styles from './HomeStyles.module.css'
import intro_video from '../assets/Truck-Transport.mp4'
import dvs from '../assets/dvs.jpg'
import rha from '../assets/rha.png'
import ea from '../assets/environmentagency.png'
import fors from '../assets/fors.jpeg'

import { HashLink } from 'react-router-hash-link';
import { FaFacebook, FaLinkedin, FaMailBulk, FaPhone, FaSearchLocation, FaTwitter } from 'react-icons/fa'


<script src="https://cdnjs.cloudflare.com/ajax/libs/react-modal/3.14.3/react-modal.min.js"
   integrity="sha512-MY2jfK3DBnVzdS2V8MXo5lRtr0mNRroUI9hoLVv2/yL3vrJTam3VzASuKQ96fLEpyYIT4a8o7YgtUs5lPjiLVQ=="
   crossorigin="anonymous"
   referrerpolicy="no-referrer"></script>


const Home = () => {

    useEffect(() => {
        var video = document.getElementById("home_video");
        video.volume=0.1;
      }, []);

    return (
        <div className={styles.home} >
            <div className={styles.contentContainer}  >
                <div className={styles.left} style={{margin: 'auto'}}>   
                    <div className={styles.description} >
                        {/* <video autoPlay loop muted playsInline className={styles.video} src={intro_video} type='video/mp4' >*/}
                        <video autoPlay loop muted playsInline className={styles.video} src={intro_video} type='video/mp4' id='home_video'>
                                <source  />
                        </video>
                        
                        <div className={styles.title}>
                            <h1 className={styles.h1 } >N.Y.A & SONS TRANS</h1>    
                        </div>
                        <div>
                            <a >
                            <br/>
                            We take pride in our client retention, and we assure you exceptional service, a high level of continuity, and commitment to promptly and professionally respond to your requests.
                            We are particular about the way we do business, and we give priority to quality customer satisfaction.
                            Exceptional, reliable and fast service, with an equally exceptional, first class reputation, then our company is the perfect choice for you.
                            <br/>
                            <br/>
                        We provide services across all of Britain and Europe.
                            <br/>
                            <br/>
                            If you are looking for a transport service provider, that has been providing quality services for many years and has a great reputation for offering superior service, as well as top quality and cost-effective freight services, then we cannot wait to do business with you.
                            </a>    
                        </div>
                        <div className={styles.buttons} >
                            <div className={styles.content}>
                                <div className={styles.title}>
                                    <h1 className={styles.h1}>Certifications</h1>    
                                </div>
                                <div className={styles.gridContent3} >
                                    <img  src={dvs} className={styles.img} alt='' />
                                    <img  src={rha} className={styles.img} alt='' />
                                    <img  src={ea} className={styles.img} alt='' />
                                    {/*<img  src={fors} className={styles.img} alt='' />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.right} style={{ margin: 'auto'}}>   
                    <div style={{paddingBottom:'50px'}}>
                        <h1 style={{paddingBottom:'10px'}}>Our services</h1>    

                        <div className={styles.linkContainer} >
                            <Link to='/services' className={styles.my_style} style={{textAlign:'left'}}>All Services</Link>
                            <HashLink to='/services#traction-only-section' className={styles.my_style} style={{textAlign:'left'}}>Traction only</HashLink>
                            <HashLink to='/services#curtainsider-section' className={styles.my_style} style={{textAlign:'left'}}>Curtainsider trailer</HashLink>
                            <HashLink to='/services#containers-section' className={styles.my_style} style={{textAlign:'left'}}>Containers trailer</HashLink>
                            <HashLink to='/services#dry-box-section' className={styles.my_style} style={{textAlign:'left'}}>Dry box trailer</HashLink>
                            <HashLink to='/services#flatbed-section' className={styles.my_style} style={{textAlign:'left'}}>Flatbed trailer</HashLink>
                            <HashLink to='/services#low-loader-section' className={styles.my_style} style={{textAlign:'left'}}>Low loader trailer</HashLink>
                            <HashLink to='/services#refrigerated-section' className={styles.my_style} style={{textAlign:'left'}}>Refrigerated trailer</HashLink>
                            <HashLink to='/services#water-tank-section' className={styles.my_style} style={{textAlign:'left'}}>Water tank trailer</HashLink>
                            <HashLink to='/services#hiab-section' className={styles.my_style} style={{textAlign:'left'}}>HIAB truck</HashLink>
                        </div>
                    </div>

                    <div>
                        <h2 style={{paddingBottom:'10px'}}>Find out more...</h2>    
                        <h2> <FaPhone style={{ marginRight: '0.5rem' }} />  <a className={styles.contact}>0044 1256 242121</a></h2> 
                        <h2> <FaSearchLocation style={{ marginRight: '0.5rem' }} /> <a className={styles.contact} href="https://www.google.com/maps/dir/53.3045166,-1.5882215/N.Y.A+%26+SONS+TRANS+LTD/@52.3124051,-2.0769942,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x487421893b083a3b:0x23c8ccf71ca43409!2m2!1d-1.0617032!2d51.2823959">Basingstoke, RG24 8AG</a></h2> 
                    
                        <div className={styles.linkContainer} >
                            <Link to='/contact' className={styles.my_style}>Contact</Link>
                            <Link to='/about' className={styles.my_style}>About</Link>
                        </div>
                    </div>
                   
                </div>
            </div>

            {/*<div className={styles.contentContainer} style={{paddingTop:'10vh', height:'auto'}}>
                
            </div>*/}
        </div>
    )
    
}


export default Home

