import React from 'react'
import Footer from '../components/Footer'
import PageLayout from '../components/PageLayout'
import Navbar from '../components/Navbar'
import ServicesPage from '../components/Services'
import ScrollToTop from "../components/utils/ScrollToTop";
import ReactGA from 'react-ga';
import { useState, useEffect } from "react";

const Services = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);
    
    return (
        <div>
            <ScrollToTop />
            <Navbar />
            <PageLayout heading='Our services' text='' />
            <ServicesPage />
            <Footer />
        </div>
        
    )
}

export default Services
