import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import style from './FooterStyles.module.css'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { FaFacebook, FaLinkedin, FaMailBulk, FaPhone, FaSearchLocation, FaWhatsapp } from 'react-icons/fa'

//class Footer extends React.Component {render()
const Footer = (transparent) => {
    
    {
        const [footer, setFooter] = useState(true)
        
        /*window.onscroll = function(ev) {
            if ((window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight + 190)) {
                setFooter(false)
                } else {
                setFooter(true)
                }
        }*/
        window.onscroll = function(ev) {
            const { scrollTop, offsetHeight } = document.documentElement;
            const { innerHeight } = window;
            const bottomOfWindow = Math.round(scrollTop) + innerHeight >= offsetHeight;
            //console.log(Math.round(scrollTop) + innerHeight,' ',offsetHeight);

            if (bottomOfWindow) {
                //console.log('Bottom of window');
                setFooter(false)
            }else{
                setFooter(true)
            }
        }
        //window.addEventListener('scroll', changeColor)

        return (
            
            <div className={footer ? style.footer:style.footer}>{//? style.footer_transparent:style.footer}>
            }
                <div className={style.footer_container}>

                    <div className={style.column}>
                        <h4 style={{margin:'auto'}}>Navigate</h4>
                        <div className={style.nav_buttons} style={{margin:'auto', display:"grid"}}>
                            <Link to='/home' className={style.btn} style={{fontSize:"15px", margin:"0px",border:"0px", borderRadius:"10px", padding:"7px"}}>Home</Link>
                            <Link to='/services' className={style.btn} style={{fontSize:"15px", margin:"0px",border:"0px", borderRadius:"10px", padding:"7px"}}>Services</Link>         
                            <Link to='/contact' className={style.btn} style={{fontSize:"15px", margin:"0px",border:"0px", borderRadius:"10px", padding:"7px"}}>Contact</Link>         
                            <Link to='/about' className={style.btn} style={{fontSize:"15px", margin:"0px",border:"0px", borderRadius:"10px", padding:"7px"}}>NYA&SONSTRANSLTD © 2022</Link>         
                        </div>
                        <div className={style.legal} style={{marginTop: '1rem'}}>

                        </div>
                    </div>

                    <div className={style.column}>
                        <h4 >Contact details</h4>
                        <div className={style.email}>
                            <p ><FaMailBulk size={20} style={{ color: '#ffffff', marginRight: '0.5rem' }} /><a href="mailto:office@nyasonstrans.co.uk?subject=Email from website">office@nyasonstrans.co.uk</a></p>
                        </div>
                        <div className={style.contact}>  
                            <p><FaSearchLocation size={20} style={{ color: '#ffffff', marginRight: '0.5rem' }} /><a href="https://www.google.com/maps/dir/53.3045166,-1.5882215/N.Y.A+%26+SONS+TRANS+LTD/@52.3124051,-2.0769942,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x487421893b083a3b:0x23c8ccf71ca43409!2m2!1d-1.0617032!2d51.2823959">N.Y.A & SONS TRANS LTD, <br/>Basingstoke RG24 8AG,<br/> United Kingdom</a></p>
                            
                        </div>
                        {/*}
                        <div className='phone'>
                            <h4><FaPhone size={20} style={{ color: '#ffffff', marginRight: '2rem' }} /> +44 7-------</h4>
                        </div>*/}
                        <p> </p>
                    </div>

                    <div className={style.column}>
                        <h4>About us</h4>
                        <p> Transport services 24/7 </p>
                        <div className={style.social} style={{marginTop: '1rem'}}>
                            {/*  <FaFacebook size={30} style={{ color: '#ffffff', marginRight: '1rem' }} onClick={()=>{window.open("https://www.facebook.com", "_blank");}}/>
                            */}
                            <FaWhatsapp  size={30} style={{ color: '#ffffff', marginRight: '1rem' }} onClick={()=>{window.open("https://api.whatsapp.com/send?phone=0447438232354", "_blank");}}/>
                            
                            <FaLinkedin size={30} style={{ color: '#ffffff', marginRight: '1rem' }} onClick={()=>{window.open("https://www.linkedin.com/in/n-y-a-and-sons-trans-ltd-56a053b9/?originalSubdomain=uk", "_blank");}}/>
                                                 
                            </div>
                    </div>


                    
                    <div className={style.column} >
                        <h4>Legal</h4>
                        <div className={style.dropdown_content} style={{display:"grid"}}>
                        <p>  <Link to='/termsofservice' className={style.btn} style={{ fontSize:"15px", margin:"0px",border:"0px", borderRadius:"10px", padding:"2px"}} >Terms of service</Link></p> 
                        <p>   <Link to='/privacypolicy' className={style.btn} style={{ fontSize:"15px", margin:"0px",border:"0px", borderRadius:"10px", padding:"2px"}}>Privacy policy</Link></p> 
                        {/*<p>   <Link to='/cookiepolicy' className={style.btn} style={{ fontSize:"15px",margin:"px",border:"0px", borderRadius:"10px", padding:"2px"}}>Cookie policy</Link></p> 
                        <p>   <Link to='/eula' className={style.btn} style={{fontSize:"15px", margin:"0px",border:"0px", borderRadius:"10px", padding:"2px"}} >EULA</Link></p>
                        </div>
                        <div className={style.legal} style={{marginTop: '1rem'}}>
                        */}
                        </div>
                        </div>
                    
                </div>
            </div>
        )
    }
}


export default Footer
