import React from "react";
import { useEffect } from "react";

import './CookiePolicyStyles.css'


const CookiePolicy = () => {
    
    return (
        <div className='content'>
            {/*<h1>Cookie Policy for Datary</h1>
                <p>This is the Cookie Policy for Datary, accessible from <a style={{color:"white"}} href="https://dataryweb.com/"><u>https://dataryweb.com/</u></a></p>

                <h2><strong>What Are Cookies</strong></h2>

                <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>

                <h2><strong>How We Use Cookies</strong></h2>

                <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>

                <h2><strong>Disabling Cookies</strong></h2>

                <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>
                <h2><strong>The Cookies We Set</strong></h2>

                <ul>
                    <li>
                        <h3>Forms related cookies</h3>
                        <p>When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</p>
                    </li>
                    <li>
                        <h3>Site preferences cookies</h3>
                        <p>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>
                    </li>
                </ul>

                <h2><strong>Third Party Cookies</strong></h2>
                <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
                <ul>
                    <li>
                        <p>From time to time we test new features and make subtle changes to the way that the site is delivered. When we are still testing new features these cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.</p>
                    </li>
                </ul>

                <h2><strong>More Information</strong></h2>
                <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</p>
                <p>For more general information on cookies, please read <a href="https://www.generateprivacypolicy.com/#cookies">"Cookies" article</a>.</p>
                <p>However if you are still looking for more information then you can contact us through one of our preferred contact methods:</p>
                <ul >
                    <li style={{color:"white", fontSize:"20px", marginTop:"20px"}}>Email: <a href="mailto:support@dataryweb.com"><u>support@dataryweb.com</u></a></li>
                </ul>*/}



            <div data-custom-class="body" className="body">
            <div><strong><span style={{fontSize: '26px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="title">COOKIE POLICY</span></span></strong></div>
            <div></div>
            <div style={{marginTop:"10px"}}>
                <span style={{color: 'rgb(255, 255, 255)'}}>
                <strong>
                    <span style={{fontSize: '15px'}}>
                    <span data-custom-class="subtitle">
                        Last updated 
                        <bdt className="question">June 05, 2022</bdt>
                    </span>
                    </span>
                </strong>
                </span>
            </div>
            <div><br /></div>
            <div style={{lineHeight: '1.5'}}>
                <span style={{color: 'rgb(255, 255, 255)'}}>
                <span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}>
                    <span data-custom-class="body_text">
                    This Cookie Policy explains how <></>
                    <bdt className="question">Datary </bdt>                    
                    <bdt className="statement-end-if-in-editor" />
                     uses cookies and similar technologies to recognize you when you visit our websites at
                    <bdt className="forloop-component" />
                    <bdt className="question"><a href="https://www.dataryweb.com" target="_blank" data-custom-class="link"><> </>www.dataryweb.com</a></bdt>
                    <bdt className="forloop-component" />
                    . It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                    <bdt className="block-component" />
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}>
                <span style={{color: 'rgb(255, 255, 255)'}}>
                <span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}>
                    <span data-custom-class="body_text">
                    In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information. 
                    <bdt className="block-component" />
                    </span>
                    <bdt className="statement-end-if-in-editor"><span data-custom-class="body_text" /></bdt>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}><br /></div>
            <div style={{lineHeight: '1.5'}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><strong><span data-custom-class="heading_1">What are cookies?</span></strong></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><span data-custom-class="body_text">Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</span></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}>
                <span style={{color: 'rgb(255, 255, 255)'}}>
                <span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}>
                    <span data-custom-class="body_text">
                    Cookies set by the website owner (in this case
                    <bdt className="question"></bdt>
                    ) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}><br /></div>
            <div style={{lineHeight: '1.5'}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><strong><span data-custom-class="heading_1">Why do we use cookies?</span></strong></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}>
                <span style={{color: 'rgb(255, 255, 255)'}}>
                <span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}>
                    <span data-custom-class="body_text">
                    We use first
                    <bdt className="block-component" />
                    and third
                    <bdt className="statement-end-if-in-editor" />
                    party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. 
                    <bdt className="block-component" />
                    Third parties serve cookies through our Websites for advertising, analytics and other purposes. 
                    <bdt className="statement-end-if-in-editor" />
                    This is described in more detail below.
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}>
                <span style={{color: 'rgb(255, 255, 255)'}}>
                <span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}>
                    <span data-custom-class="body_text">
                    The specific types of first
                    <bdt className="block-component" />
                    and third
                    <bdt className="statement-end-if-in-editor" />
                    party cookies served through our Websites and the purposes they perform are described below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}><br /></div>
            <div style={{lineHeight: '1.5'}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span id="control" style={{color: 'rgb(255, 255, 255)'}}><strong><span data-custom-class="heading_1">How can I control cookies?</span></strong></span></span></span></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</span></span></span></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}></div>
            <div style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.</span></span></span></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}></div>
            <div style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit&nbsp;</span></span><span style={{fontSize: '15px', color: 'rgb(48, 48, 48)'}}><span data-custom-class="body_text"><a data-custom-class="link" href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a></span></span><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">&nbsp;or&nbsp;</span></span><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text"><a data-custom-class="link" data-fr-linked="true" href="http://www.youronlinechoices.com" target="_blank">http://www.youronlinechoices.com</a></span></span><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">.</span></span></span></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}></div>
            <div style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">The specific types of first and third party cookies served through our Websites and the purposes they perform are described in the table below (please note that the specific&nbsp;</span><span data-custom-class="body_text">cookies served may vary depending on the specific Online Properties you visit):</span></span></span><span style={{fontSize: '15px'}} /><span data-custom-class="heading_2" style={{color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px'}}><strong><u><br /><br />Essential website cookies:</u></strong></span></span></div>
            <div>
                <div></div>
                <div style={{lineHeight: '1.5', marginTop:"10px"}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><span data-custom-class="body_text">These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas.</span></span></span></div>
                <p style={{fontSize: '15px', lineHeight: '1.5'}}><span data-custom-class="body_text" /></p>
                <section data-custom-class="body_text" style={{width: '100%', border: '1px solid rgb(230, 230, 230)', margin: '0px 0px 10px', borderRadius: '3px', textAlign: 'left', marginTop:"10px"}}>
                <div style={{padding: '8px 13px', borderBottom: '1px solid #e6e6e6'}}>
                    <table>
                    <tbody>
                        <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                        <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Name:</td>
                        <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>__tlbcpv</span></td>
                        </tr>
                        <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: 0, verticalAlign: 'top'}}>
                        <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Purpose:</td>
                        <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>Used to record unique visitor views of the consent banner.</span></td>
                        </tr>
                        <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                        <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Provider:</td>
                        <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>.termly.io</span></td>
                        </tr>
                        <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                        <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Service:</td>
                        <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>Termly  <a href="https://termly.io/our-privacy-policy/" style={{color: '#1a98eb !important'}} target="_blank">View Service Privacy Policy</a> &nbsp;</span></td>
                        </tr>
                        <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                        <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Country:</td>
                        <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>United States</span></td>
                        </tr>
                        <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                        <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Type:</td>
                        <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>http_cookie</span></td>
                        </tr>
                        <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                        <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Expires in:</td>
                        <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>1 year</span></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </section>
            </div>
            <div />
            <div style={{lineHeight: '1.5'}}><span data-custom-class="heading_2" style={{color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px'}}><strong><u><br />Unclassified cookies:</u></strong></span></span></div>
            <div>
                <p style={{fontSize: '15px', lineHeight: '1.5', marginTop:"10px"}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><span data-custom-class="body_text">These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.</span></span></span></p>
                <div>
                <span data-custom-class="body_text" >
                    <section data-custom-class="body_text" style={{width: '100%', border: '1px solid #e6e6e6', margin: '0 0 10px', borderRadius: '3px', marginTop:"10px"}}>
                    <div style={{padding: '8px 13px', borderBottom: '1px solid #e6e6e6' }}>
                        <table>
                        <tbody>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Name:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>x-cdn</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: 0, verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Purpose:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>payment</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Provider:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>.paypal.com</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Country:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>United States</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Type:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>server_cookie</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Expires in:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>session</span></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </section>
                    <section data-custom-class="body_text" style={{width: '100%', border: '1px solid #e6e6e6', margin: '0 0 10px', borderRadius: '3px'}}>
                    <div style={{padding: '8px 13px', borderBottom: '1px solid #e6e6e6'}}>
                        <table>
                        <tbody>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Name:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>__paypal_storage__</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: 0, verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Purpose:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>payment</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Provider:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>www.dataryweb.com</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Country:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>United States</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Type:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>html_local_storage</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Expires in:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>persistent</span></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </section>
                    <section data-custom-class="body_text" style={{width: '100%', border: '1px solid #e6e6e6', margin: '0 0 10px', borderRadius: '3px'}}>
                    <div style={{padding: '8px 13px', borderBottom: '1px solid #e6e6e6'}}>
                        <table>
                        <tbody>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Name:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>ts_c</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: 0, verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Purpose:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>payment</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Provider:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>.paypal.com</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Country:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>United States</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Type:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>server_cookie</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Expires in:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>2 years 11 months 29 days</span></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </section>
                    <section data-custom-class="body_text" style={{width: '100%', border: '1px solid #e6e6e6', margin: '0 0 10px', borderRadius: '3px'}}>
                    <div style={{padding: '8px 13px', borderBottom: '1px solid #e6e6e6'}}>
                        <table>
                        <tbody>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Name:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>l7_az</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: 0, verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Purpose:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>payment</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Provider:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>.paypal.com</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Country:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>United States</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Type:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>http_cookie</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Expires in:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>30 minutes</span></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </section>
                    <section data-custom-class="body_text" style={{width: '100%', border: '1px solid #e6e6e6', margin: '0 0 10px', borderRadius: '3px'}}>
                    <div style={{padding: '8px 13px', borderBottom: '1px solid #e6e6e6'}}>
                        <table>
                        <tbody>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Name:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99f0ff', wordBreak: 'break-all'}}>tsrce</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: 0, verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Purpose:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>payment</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Provider:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>.paypal.com</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Country:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>United States</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Type:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>http_cookie</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Expires in:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>3 days</span></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </section>
                    <section data-custom-class="body_text" style={{width: '100%', border: '1px solid #e6e6e6', margin: '0 0 10px', borderRadius: '3px'}}>
                    <div style={{padding: '8px 13px', borderBottom: '1px solid #e6e6e6'}}>
                        <table>
                        <tbody>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Name:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>ts</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: 0, verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Purpose:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>payment</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Provider:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>.paypal.com</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Country:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>United States</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Type:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>http_cookie</span></td>
                            </tr>
                            <tr style={{fontFamily: 'Roboto, Arial', fontSize: '12px', lineHeight: '1.67', margin: '0 0 8px', verticalAlign: 'top'}}>
                            <td style={{textAlign: 'right', color: '#FFD9B3', minWidth: '80px'}}>Expires in:</td>
                            <td style={{display: 'inline-block', marginLeft: '5px'}}><span style={{color: '#99F0FF', wordBreak: 'break-all'}}>2 years 11 months 29 days</span></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </section>
                </span>
                </div>
            </div>
            <div style={{lineHeight: '1.5'}}><br /></div>
            <div style={{lineHeight: '1.5'}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><strong><span data-custom-class="heading_1">What about other tracking technologies, like web beacons?</span></strong></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5'}}>
                <span style={{color: 'rgb(255, 255, 255)'}}>
                <span style={{color: 'rgb(255, 255, 255)', fontSize: '15px', marginTop:"10px"}}>
                    <span data-custom-class="body_text">Cookies are not the only way&nbsp;</span>
                    <span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}>
                    <span data-custom-class="body_text">
                        to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enable us to recognize when someone has visited our Websites
                        <bdt className="block-component" />
                        or opened an e-mail including them
                        <bdt className="statement-end-if-in-editor" />
                        . This allows us, for example, to monitor&nbsp;
                    </span>
                    <span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}>
                        <span style={{color: 'rgb(255, 255, 255)'}}>
                        <span data-custom-class="body_text">the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of e-mail marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</span>
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}><br /></div>
            <div style={{lineHeight: '1.5'}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)'}}><strong><span data-custom-class="heading_1">Do you use Flash cookies or Local Shared Objects?</span></strong></span></span></span></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs") to, among other things, collect and store information about your use of our services, fraud prevention and for other site operations.</span></span></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}></div>
            <div style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the&nbsp;</span></span><span data-custom-class="body_text"><span style={{color: 'rgb(255, 255, 255)'}}><a data-custom-class="link" href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html" target="_BLANK"><span style={{fontSize: '15px'}}>Website Storage Settings Panel</span></a></span><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}>. You can also control Flash Cookies by going to the&nbsp;</span><span style={{color: 'rgb(255, 255, 255)'}}><a data-custom-class="link" href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html" target="_BLANK"><span style={{fontSize: '15px'}}>Global Storage Settings Panel</span></a></span></span><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">&nbsp;and&nbsp;</span><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">following the instructions (which may include instructions that explain, for example, how to delete existing Flash Cookies (referred to "information" on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the page you are on at the time).</span></span></span></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}></div>
            <div style={{lineHeight: '1.5'}}>
                <span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our services or online content.</span></span></span>
                <span style={{color: 'rgb(255, 255, 255)'}}>
                <span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}>
                    <span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}>
                    <span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}>
                        <span style={{color: 'rgb(255, 255, 255)'}}>
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}><br /></div>
            <div style={{lineHeight: '1.5'}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)', fontSize: '15px'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><strong><span data-custom-class="heading_1">Do you serve targeted advertising?</span></strong></span></span></span></span></span></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}>
                <span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other details that directly identify you unless you choose to provide these.</span></span>
                <span style={{color: 'rgb(127, 127, 127)'}}>
                <span style={{color: 'rgb(89, 89, 89)', fontSize: '15px'}}>
                    <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                    <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                        <span style={{color: 'rgb(89, 89, 89)'}}>
                        <bdt className="statement-end-if-in-editor" />
                        </span>
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}><br /></div>
            <div style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><strong><span data-custom-class="heading_1">How often will you update this Cookie Policy?</span></strong></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}><span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">We may update&nbsp;</span><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</span></span></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}><span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><span data-custom-class="body_text">The date at the top of this Cookie Policy indicates when it was last updated.</span></span></span></span></div>
            <div style={{lineHeight: '1.5'}}><br /></div>
            <div style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}><span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}><span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}><strong><span data-custom-class="heading_1">Where can I get further information?</span></strong></span></span></span></div>
            <div style={{lineHeight: '1.5'}}></div>
            <div style={{lineHeight: '1.5', marginTop:"10px"}}>
                <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                    <span style={{fontSize: '15px', color: 'rgb(255, 255, 255)'}}>
                    <span data-custom-class="body_text">
                        If you have any questions about our use of cookies or other technologies, please email us at 
                        <bdt className="question"><> </>support@dataryweb.com</bdt>
                        {/*or by post to:*/}
                    </span>
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}><span data-custom-class="body_text"><br /></span></div>
            <div style={{lineHeight: '1.5'}}>
                <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                    <span data-custom-class="body_text">
                    <bdt className="question"></bdt>
                    </span>
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}>
                <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                <bdt className="question"><span data-custom-class="body_text"></span></bdt>
                <span data-custom-class="body_text">
                    <bdt className="block-component" />
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}>
                <span data-custom-class="body_text">
                <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                    <bdt className="question"></bdt>
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                </span>
                </span>
            </div>
            <div style={{lineHeight: '1.5'}}>
                <span data-custom-class="body_text">
                <span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                    {/*Phone:*/} 
                    <bdt className="question"></bdt>
                    <bdt className="block-component" />
                </span>
                </span>
            </div>
            </div>
            <div className="bottom">
                <a></a>
            </div>
        </div>
    )
}

export default CookiePolicy
