import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {FaBars, FaTimes} from 'react-icons/fa'
import style from './NavbarStyles.module.css'
import logo from '../assets/logo.png'


const Navbar = ({page}) => {
    const [menuClick, setMenuClick] = useState(false)
    const [pagePosition, setPagePosition] = useState(true)

    const changePagePosition = () => {
        if(window.scrollY >= 100) {
            setPagePosition(false)
        } else {
            setPagePosition(true)
        }
    }
    window.addEventListener('scroll', changePagePosition)

    const handleMenuClick = () => setMenuClick(!menuClick)
    
    return (
            <div className={pagePosition ? style.header :style.header_transparent} >
                <Link to='/home' className={style.logo_container}>
                    <img className={style.logo} src={logo} alt="Logo"></img>
                </Link> 
                <ul className={menuClick ? `${style.nav_menu} ${style.active}`: style.nav_menu} >
                    <li>
                        <Link to='/home' className={style.link_black}>Home</Link>
                    </li>

                    <li>
                        <Link to='/services' className={style.link_black}>Services</Link>
                    </li>
                    
                    <li>
                        <Link to='/contact' className={style.link_black}>Contact</Link>
                    </li>

                    <li>
                        <Link to='/about' className={style.link_black}>About</Link>
                    </li>

                    {/*
                    <li>
                        <Link to='/about' className={page=='home' ? (menuClick ? style.link_black:style.link_white) : style.link_black}>About</Link>
                    </li>
                    */}
                </ul>
                <div className={style.hamburger} onClick={handleMenuClick}>
                    {menuClick ? (<FaTimes size={20} style={{color: page=='home' ? 'black' : 'black'}} />) : (<FaBars size={20} style={{color: page=='home' ? 'black' : 'black'}} />)}
                </div>
            </div>
    )
}

Navbar.defaultProps = {
    page: 'default'
}
export default Navbar
