import React,{useRef,useEffect, useState} from "react";
import styles from'./ServicesStyles.module.css'
import intro_video from '../assets/Intro_video.mp4'
import dvs from '../assets/dvs.jpg'
import rha from '../assets/rha.png'
import { FaFacebook, FaLinkedin, FaMailBulk, FaPhone, FaSearchLocation, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'


import containerstransporttrailer from '../assets/services/containerstransporttrailer.jpeg'
import curtainsidertrailer1 from '../assets/services/curtainsidertrailer1.jpeg'
import curtainsidertrailer2 from '../assets/services/curtainsidertrailer2.jpeg'
import dryboxtrailer from '../assets/services/dryboxtrailer.jpeg'
import flatbedtrailer from '../assets/services/flatbedtrailer.jpeg'
import lowloadertrailer from '../assets/services/lowloadertrailer.jpeg'
import refrigeratedtrailer from '../assets/services/refrigeratedtrailer.jpeg'
import hiabtrailer from '../assets/services/hiab.jpeg'
import tractiononly from '../assets/services/tractiononly.jpeg'
import watertanktrailer from '../assets/services/watertanktrailer.jpeg'


const Services1 = () => {
    const [imgIsLoading, setImgIsLoading] = useState(true)

    return (
        <div className={styles.service}>
            {/*1*/}
            <div className={styles.contentContainer} id="traction-only-section" style={{ marginTop: '3vh'}}>
                <div className={styles.contentServiceContainer} >
                    <div className={styles.left} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Traction only</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                                <p className={styles.p}>Traction only is a service provided in which just a tractor unit 
                                will be supplied to transport your own trailers anywhere in the United Kingdom.
                                The transport and logistics industry can be very competitive and sometimes companies 
                                will find they cannot cover all of their deliveries on their own fleet.</p>
                            </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>

                    <div className={styles.right} >
                        <div className={styles.img_container}>
                            <img  src={tractiononly} className={styles.img} alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>
                </div>
            </div>
            {/*2*/}
            <div className={styles.contentContainer} id="curtainsider-section">
                <div className={styles.contentServiceContainer} >
                    <div className={styles.left} >
                        <div className={styles.img_container}>             
                            <img  src={curtainsidertrailer1} className={styles.img} alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>
                
                    <div className={styles.right} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Curtainsider trailer</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                            <p className={styles.p}>A curtainsider trailer, also known as a 'tautliner',
                             is the generic name for a curtain sided type of trailer. It is the haulage industry's most popular 
                             type of trailer and is used to carry a variety of different types of goods, which may be loaded / un-loaded 
                             from the side when the 'curtain' is open.</p>
                            </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*3*/}
            <div className={styles.contentContainer} id="containers-section">
                <div className={styles.contentServiceContainer} >
                    <div className={styles.left} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Containers trailer</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                                <p className={styles.p}>Container trailers are usually made like truck semitrailers, 
                                with a closed or open body (universal or specialized), a volume of at least 15 cu m,
                                and a carrying capacity of 6 to 30+ tons. Container trailers can be towed on highways;
                                and they are also loaded and unloaded from railroad flatcars and special ships by truck 
                                tractors.</p>
                            </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>

                    <div className={styles.right} >
                        <div className={styles.img_container}>
                            <img  src={containerstransporttrailer} className={styles.img} alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>
                </div>
            </div>
            {/*4*/}
            <div className={styles.contentContainer} id="dry-box-section">
                <div className={styles.contentServiceContainer} >
                    <div className={styles.left} >
                        <div className={styles.img_container}>
                            <img  src={dryboxtrailer} className={styles.img} alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>

                    <div className={styles.right} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Dry box trailer</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                            <p className={styles.p}>A dry van is a type of semi-trailer that's fully enclosed to protect 
                            shipments from outside elements. Designed to carry palletized, boxed or loose freight, 
                            dry vans aren't temperature-controlled (unlike refrigerated “reefer” units) and can't 
                            carry oversized shipments (unlike flatbed trailers).</p>
                        </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*5*/}
            <div className={styles.contentContainer} id="flatbed-section">
                <div className={styles.contentServiceContainer} >
                    <div className={styles.left} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Flatbed trailer</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                            <p className={styles.p}>A flatbed trailer, also known as an open platform trailer, 
                            is a flat without side walls. This type of trailer is suitable for special transport of 
                            wide and long goods. The total length of the trailer can be used for loads, as the flatbed
                            trailer is completely flat.</p>
                        </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right} >
                        <div className={styles.img_container}>             
                            <img src={flatbedtrailer}  alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>
                </div>
            </div>
            {/*6*/}
            <div className={styles.contentContainer} id="low-loader-section" >
                <div className={styles.contentServiceContainer} >
                    <div className={styles.left} >
                        <div className={styles.img_container}>
                            <img  src={lowloadertrailer} className={styles.img} alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>

                    <div className={styles.right} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Low loader trailer</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                            <p className={styles.p}>A semi-trailer with two drops in deck height: 
                            one right after the gooseneck and one right before the wheels. 
                            This allows the deck to be extremely low compared with other trailers.
                            It offers the ability to carry legal loads up to 12 ft tall, which other trailers cannot.</p>
                            </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact#' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*7*/}
            <div className={styles.contentContainer} id="refrigerated-section">
                <div className={styles.contentServiceContainer} >
                    <div className={styles.left} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Refrigerated trailer</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                            <p className={styles.p}>A refrigerated trailer is an insulated box trailer 
                            where it is possible to control the temperature. 
                            The refrigerated trailers are suitable for temperature sensitive products, 
                            such as food, drink or pharmaceuticals products.</p>
                            </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>

                    <div className={styles.right} >
                        
                        <div className={styles.img_container}>             
                            <img  src={refrigeratedtrailer} className={styles.img} alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>
                </div>
            </div>
            {/*9*/}
            <div className={styles.contentContainer} id="water-tank-section">
                <div className={styles.contentServiceContainer} >
                    <div className={styles.left} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Water tank trailer</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                            <p className={styles.p}>A truck water tank trailer is a versatile mobile unit that attaches to a truck chassis to transport and store 
                            significant amounts of water, including drinking water. It consists of a robust tank and secure fittings for easy filling and discharge. 
                            This versatile trailer is used in construction, agriculture, firefighting, emergencies, and other industries, providing an efficient 
                            solution for on-site water supply, including potable water needs. Its mobility and compatibility with trucks make 
                            it a reliable choice for various applications.</p>
                            </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>

                    <div className={styles.right} >
                        
                        <div className={styles.img_container}>             
                            <img  src={watertanktrailer} className={styles.img} alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>
                </div>
            </div>
            {/*8*/}
            <div className={styles.contentContainer} id="hiab-section">
                <div className={styles.contentServiceContainer} >
                    
                    <div className={styles.left} >
                        
                        <div className={styles.img_container}>             
                            <img  src={hiabtrailer} className={styles.img} alt='' style={imgIsLoading ? {display:'none'}:{}} onLoad={()=>setImgIsLoading(false)}/>
                        </div>
                    </div>
                    <div className={styles.right} >
                        <div className={styles.title}>
                            <h1 style={{color:'black'}}>Hiab truck</h1>    
                        </div>

                        <div className={styles.description}>
                            <div className={styles.top}>
                            <p className={styles.p}>An artic Hiab is a vehicle or lorry which is made up of a truck with 
                            the lorry mounted crane situated behind the cab. These trucks can move around unit only or 
                            can pull a variety of trailers</p>
                            </div>
                            
                            <div className={styles.bottom}>
                                <Link to='/contact' className={styles.my_style} onClick={() => {
                                    ReactGA.event({
                                    category: 'User',
                                    action: 'Customer pressed contact button on Service page'  
                                });           
                                }}>Contact</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
    )
}

  const Services = () => {
    const [imgIsLoading, setImgIsLoading] = useState(true);
  
    const contentData1 = [
        {
            id: 'traction-only-section',
            title: 'Traction only',
            description: 'Traction only is a service provided in which just a tractor unit will be supplied to transport your own trailers anywhere in the United Kingdom. The transport and logistics industry can be very competitive and sometimes companies will find they cannot cover all of their deliveries on their own fleet.',
            imgSrc: tractiononly,
            imgAlt: 'Traction only',
        },
        {
            id: 'curtainsider-section',
            title: 'Curtainsider trailer',
            description: "A curtainsider trailer, also known as a 'tautliner', is the generic name for a curtain sided type of trailer. It is the haulage industry's most popular type of trailer and is used to carry a variety of different types of goods, which may be loaded / un-loaded from the side when the 'curtain' is open.",
            imgSrc: curtainsidertrailer1,
            imgAlt: 'Curtainsider trailer',
        },
        {
            id: 'containers-section',
            title: 'Containers trailer',
            description: 'Container trailers are usually made like truck semitrailers, with a closed or open body (universal or specialized), a volume of at least 15 cu m, and a carrying capacity of 6 to 30+ tons. Container trailers can be towed on highways; and they are also loaded and unloaded from railroad flatcars and special ships by truck tractors.',
            imgSrc: containerstransporttrailer,
            imgAlt: 'Containers trailer',
        },
        {
            id: 'dry-box-section',
            title: 'Dry box trailer',
            description: 'A dry van is a type of semi-trailer that\'s fully enclosed to protect shipments from outside elements. Designed to carry palletized, boxed or loose freight, dry vans aren\'t temperature-controlled (unlike refrigerated “reefer” units) and can\'t carry oversized shipments (unlike flatbed trailers).',
            imgSrc: dryboxtrailer,
            imgAlt: 'Dry box trailer',
        },
        {
            id: "flatbed-section",
            title: "Flatbed trailer",
            description: "A flatbed trailer, also known as an open platform trailer, is a flat without side walls. This type of trailer is suitable for special transport of wide and long goods. The total length of the trailer can be used for loads, as the flatbed trailer is completely flat.",
            imgSrc: flatbedtrailer,
            buttonAction: 'Customer pressed contact button on Service page',
            buttonText: 'Contact'
        },
        {
            id: "low-loader-section",
            title: "Low loader trailer",
            description: "A semi-trailer with two drops in deck height: one right after the gooseneck and one right before the wheels. This allows the deck to be extremely low compared with other trailers. It offers the ability to carry legal loads up to 12 ft tall, which other trailers cannot.",
            img: lowloadertrailer,
            buttonAction: 'Customer pressed contact button on Service page',
            buttonText: 'Contact'
        },
        {
            id: "refrigerated-section",
            title: "Refrigerated trailer",
            description: "A refrigerated trailer is an insulated box trailer where it is possible to control the temperature. The refrigerated trailers are suitable for temperature sensitive products, such as food, drink or pharmaceuticals products.",
            img: refrigeratedtrailer,
            buttonAction: 'Customer pressed contact button on Service page',
            buttonText: 'Contact'
        },
        {
            id: "water-tank-section",
            title: "Water tank trailer",
            description: "A truck water tank trailer is a versatile mobile unit that attaches to a truck chassis to transport and store significant amounts of water, including drinking water. It consists of a robust tank and secure fittings for easy filling and discharge. This versatile trailer is used in construction, agriculture, firefighting, emergencies, and other industries, providing an efficient solution for on-site water supply, including potable water needs. Its mobility and compatibility with trucks make it a reliable choice for various applications.",
            img: watertanktrailer,
            buttonAction: 'Customer pressed contact button on Service page',
            buttonText: 'Contact'
        },
        {
            id: "hiab-section",
            title: "Hiab truck",
            description: "An artic Hiab is a vehicle or lorry which is made up of a truck with the lorry mounted crane situated behind the cab. These trucks can move around unit only or can pull a variety of trailers",
            img: hiabtrailer,
            buttonAction: 'Customer pressed contact button on Service page',
            buttonText: 'Contact'
        },
    ];

    const contentData = [
        {
            id: "traction-only-section",
            title: "Traction only",
            description: "Traction only is a service provided in which just a tractor unit will be supplied to transport your own trailers anywhere in the United Kingdom. The transport and logistics industry can be very competitive and sometimes companies will find they cannot cover all of their deliveries on their own fleet.",
            imgSrc: tractiononly,
            imgAlt: "Traction only"
        },
        {
            id: "curtainsider-section",
            title: "Curtainsider trailer",
            description: "A curtainsider trailer, also known as a 'tautliner', is the generic name for a curtain sided type of trailer. It is the haulage industry's most popular type of trailer and is used to carry a variety of different types of goods, which may be loaded / un-loaded from the side when the 'curtain' is open.",
            imgSrc: curtainsidertrailer1,
            imgAlt: "Curtainsider trailer"
        },
        {
            id: "containers-section",
            title: "Containers trailer",
            description: "Container trailers are usually made like truck semitrailers, with a closed or open body (universal or specialized), a volume of at least 15 cu m, and a carrying capacity of 6 to 30+ tons. Container trailers can be towed on highways; and they are also loaded and unloaded from railroad flatcars and special ships by truck tractors.",
            imgSrc: containerstransporttrailer,
            imgAlt: "Containers trailer"
        },
        {
            id: "dry-box-section",
            title: "Dry box trailer",
            description: "A dry van is a type of semi-trailer that's fully enclosed to protect shipments from outside elements. Designed to carry palletized, boxed or loose freight, dry vans aren't temperature-controlled (unlike refrigerated “reefer” units) and can't carry oversized shipments (unlike flatbed trailers).",
            imgSrc: dryboxtrailer,
            imgAlt: "Dry box trailer"
        },
        {
            id: "flatbed-section",
            title: "Flatbed trailer",
            description: "A flatbed trailer, also known as an open platform trailer, is a flat without side walls. This type of trailer is suitable for special transport of wide and long goods. The total length of the trailer can be used for loads, as the flatbed trailer is completely flat.",
            imgSrc: flatbedtrailer,
            imgAlt: "Flatbed trailer"
        },
        {
            id: "low-loader-section",
            title: "Low loader trailer",
            description: "A semi-trailer with two drops in deck height: one right after the gooseneck and one right before the wheels. This allows the deck to be extremely low compared with other trailers. It offers the ability to carry legal loads up to 12 ft tall, which other trailers cannot.",
            imgSrc: lowloadertrailer,
            imgAlt: "Low loader trailer"
        },
        {
            id: "refrigerated-section",
            title: "Refrigerated trailer",
            description: "A refrigerated trailer is an insulated box trailer where it is possible to control the temperature. The refrigerated trailers are suitable for temperature sensitive products, such as food, drink or pharmaceuticals products.",
            imgSrc: refrigeratedtrailer,
            imgAlt: "Refrigerated trailer"
        },
        {
            id: "water-tank-section",
            title: "Water tank trailer",
            description: "A truck water tank trailer is a versatile mobile unit that attaches to a truck chassis to transport and store significant amounts of water, including drinking water. It consists of a robust tank and secure fittings for easy filling and discharge. This versatile trailer is used in construction, agriculture, firefighting, emergencies, and other industries, providing an efficient solution for on-site water supply, including potable water needs. Its mobility and compatibility with trucks make it a reliable choice for various applications.",
            imgSrc: watertanktrailer,
            imgAlt: "Water tank trailer"
        },
        {
            id: "hiab-section",
            title: "Hiab truck",
            description: "An artic Hiab is a vehicle or lorry which is made up of a truck with the lorry mounted crane situated behind the cab. These trucks can move around unit only or can pull a variety of trailers",
            imgSrc: hiabtrailer,
            imgAlt: "Hiab truck"
        },
        // other sections...
    ];
    
    const ContentSection = ({ id, title, description, imgSrc, imgAlt, isLeftAligned }) => {
      return (
        <div className={styles.contentContainer} id={id}>
          <div className={styles.contentServiceContainer}>
            <div className={isLeftAligned ? styles.left : styles.right}>
              {isLeftAligned ? (
                <div>
                    <div className={styles.title}>
                      <h1 style={{ color: 'black' }}>{title}</h1>
                    </div>
                    <div className={styles.description}>
                      <div className={styles.top}>
                        <p className={styles.p}>{description}</p>
                      </div>
                      <div className={styles.bottom}>
                        <Link to='/contact' className={styles.my_style} onClick={() => {
                          ReactGA.event({
                            category: 'User',
                            action: 'Customer pressed contact button on Service page'
                          });
                        }}>Contact</Link>
                      </div>
                    </div>
                  </div>              
                ) : (
                    <div className={styles.img_container}>
                        <img src={imgSrc} className={styles.img} alt={imgAlt} style={imgIsLoading ? { display: 'none' } : {}} onLoad={() => setImgIsLoading(false)} />
                    </div>                
                )}
            </div>
  
            <div className={isLeftAligned ? styles.right : styles.left}>
              {!isLeftAligned ? (
                    <div>
                        <div className={styles.title}>
                        <h1 style={{ color: 'black' }}>{title}</h1>
                        </div>
                        <div className={styles.description}>
                        <div className={styles.top}>
                            <p className={styles.p}>{description}</p>
                        </div>
                        <div className={styles.bottom}>
                            <Link to='/contact' className={styles.my_style} onClick={() => {
                            ReactGA.event({
                                category: 'User',
                                action: 'Customer pressed contact button on Service page'
                            });
                            }}>Contact</Link>
                        </div>
                        </div>
                    </div>              
                ) : (
                    <div className={styles.img_container}>
                      <img src={imgSrc} className={styles.img} alt={imgAlt} style={imgIsLoading ? { display: 'none' } : {}} onLoad={() => setImgIsLoading(false)} />
                    </div>                
                )}
            </div>
          </div>
        </div>
      );
    };
  
    return (
      <div className={styles.service}>
        {contentData.map((content, index) => (
          <ContentSection
            id={content.id}
            title={content.title}
            description={content.description}
            imgSrc={content.imgSrc}
            imgAlt={content.imgAlt}
            imgIsLoading={imgIsLoading}
            isLeftAligned={index % 2 === 0}
          />
        ))}
      </div>
    );
  };  

  export default Services;