import React, { useEffect, useRef } from 'react'
import styles from './FormStyles.module.css'
import emailjs from 'emailjs-com';
import { FaFacebook, FaLinkedin, FaMailBulk, FaPhone, FaSearchLocation, FaTwitter } from 'react-icons/fa'
//import emailjs from '@emailjs/browser';
import ReactGA from 'react-ga';


const Form = () => {
    const form = useRef();

    function sendEmail (e){
      e.preventDefault();

      ReactGA.event({
        category: 'User',
        action: 'Customer sent email from website'  
      });
  
      emailjs.sendForm('service_16mrkxw1', 'template_2jisrqk', e.target, 'xyPqeoanYyvNc5Nuc2')
        .then((result) => {
            window.location.reload()
        }, (error) => {
            console.log(error.text);
        });
    };
    {/* To cehck if we need to return desktop or mobile version */}
    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 1050;
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    {/* Check if componenet should return desktop or mobile version */}
    return (width > breakpoint) ? (
        
        <div className={styles.body}>
            <div className={styles.contactsContainer}>
                <div>
                    <h1 style={{paddingBottom:'10px'}}>Contact</h1>    
                    <h1> 
                        <FaPhone size={20} style={{ marginRight: '2rem' }} /> 
                        0044 1256 24212
                    </h1> 
                    <h1> 
                        <FaSearchLocation size={20} style={{ marginRight: '2rem' }} /> 
                        <a href="https://www.google.com/maps/dir/53.3045166,-1.5882215/N.Y.A+%26+SONS+TRANS+LTD/@52.3124051,-2.0769942,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x487421893b083a3b:0x23c8ccf71ca43409!2m2!1d-1.0617032!2d51.2823959">N.Y.A & SONS TRANS LTD, Basingstoke RG24 8AG</a></h1> 
                    <h1> 
                        <FaMailBulk size={20} style={{ marginRight: '2rem'}} /> 
                        <a href="mailto:office@nyasonstrans.co.uk">office@nyasonstrans.co.uk</a>
                    </h1> 

                    <form style={{display:'none'}} ref={form} onSubmit={sendEmail}>
                        <h1>Send us an email</h1>    
                        <label>Your name</label>
                        <input name="from_name" type='text' required></input>
                        <label>Your email</label>
                        <input name = "from_email" type='email' required></input>
                        <label>Subject</label>
                        <input name="subject" type='text' required></input>
                        <label>Message</label>
                        <textarea name="message" rows='3' placeholder='Type your short message here' required/>
                        <button className={styles.btn_my_style} type="submit" value="Send" style={{fontSize:'20px'}} >Send</button>
                        {/* <button className='btn' ref={form} onClick= {sendEmail}  >Submit</button> */}
                    </form>
                </div>
                <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2495.561194257056!2d-1.063897248329712!3d51.28239587949873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487421893b083a3b%3A0x23c8ccf71ca43409!2sN.Y.A%20%26%20SONS%20TRANS.LTD!5e0!3m2!1sen!2suk!4v1657804012571!5m2!1sen!2suk" width="100%" height="70%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            
        </div>
    ):
    (<div className={styles.body}>
        <div className={styles.contactsContainer}>
            <div>
                <h1 style={{paddingBottom:'10px'}}>Contact</h1>    
                <h1> <FaPhone size={20} style={{ marginRight: '2rem' }} /> 0044 1256 242121</h1> 
                <h1> <FaSearchLocation size={20} style={{ marginRight: '2rem' }} /> <a href="https://www.google.com/maps/dir/53.3045166,-1.5882215/N.Y.A+%26+SONS+TRANS+LTD/@52.3124051,-2.0769942,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x487421893b083a3b:0x23c8ccf71ca43409!2m2!1d-1.0617032!2d51.2823959">N.Y.A & SONS TRANS LTD, Basingstoke RG24 8AG</a></h1> 
                <h1 style={{ marginRight: '2rem', marginBottom: '2rem'}}>
                    <FaMailBulk size={20} style={{ marginRight: '2rem'}} /> <a href="mailto:office@nyasonstrans.co.uk">office@nyasonstrans.co.uk</a>
                </h1>
                <div >
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2495.561194257056!2d-1.063897248329712!3d51.28239587949873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487421893b083a3b%3A0x23c8ccf71ca43409!2sN.Y.A%20%26%20SONS%20TRANS.LTD!5e0!3m2!1sen!2suk!4v1657804012571!5m2!1sen!2suk" width="100%" height="450vh" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <form  style={{display:'none'}}  ref={form} onSubmit={sendEmail}>
                    <h1>Send us an email</h1>    
                    <label>Your name</label>
                    <input name="from_name" type='text' required></input>
                    <label>Your email</label>
                    <input name = "from_email" type='email' required></input>
                    <label>Subject</label>
                    <input name="subject" type='text' required></input>
                    <label>Message</label>
                    <textarea name="message" rows='3' placeholder='Type your short message here' required/>
                    <input type="submit" value="Send" style={{fontSize:'20px'}} />
                    {/* <button className='btn' ref={form} onClick= {sendEmail}  >Submit</button> */}
                </form>
            </div>
        </div>
        
    </div>);
}

export default Form
